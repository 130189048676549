import axios from '@/config/httpConfig'

//获取对象字段列表
export function getobjfield(objId) {
    return axios.post('/field/getFieldList', {
        objId: objId
    }, {

    })
}
// 获获取选项列表值
export function GetViewGetSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}
// 获取币种信息
export function GetCurrencyInfo(data) {
    return axios.post('/currency/getCurrencyInfo', data)
}
// 获取视图详细信息
export function GetViewInfo(data) {
    return axios.post('/view/getViewInfo', data)
}
// 获取记录类型
export function getRecordType(data) {
    return axios.post('/batch/getRecordType', data)
}
// 获取选项列表值
export function getSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}
// 保存重大事件类型-高含宇
export function saveMajoreventType(args) {
    return axios.post('majoreventtype/saveMajoreventType', args)
}
//获取重大事件类型列表
export function getMajoreventTypeList(args) {
    return axios.post('majoreventtype/getMajoreventTypeList', args)
}
// 获取重大事件类型详细信息
export function getMajoreventTypeDetail(args) {
    return axios.post('majoreventtype/getMajoreventTypeDetail', args)
}
// 删除重大事件类型
export function deleteMajoreventType(args) {
    return axios.post('majoreventtype/deleteMajoreventType', args)
}

//保存权利过程
export function saveEntitlementProcess(args) {
    return axios.post('entitlementprocess/saveEntitlementProcess', args)
}

//查询权利过程列表
export function getEntitlementProcessList(args) {
    return axios.post('entitlementprocess/getEntitlementProcessList', args)
}

//查询权利过程详细信息
export function getEntitlementProcessDetail(args) {
    return axios.post('entitlementprocess/getEntitlementProcessDetail', args)
}

//删除权利过程
export function deleteEntitlementProcess(args) {
    return axios.post('entitlementprocess/deleteEntitlementProcess', args)
}

//保存权利过程中的重大事件
export function saveEventInEntitlementProcess(args) {
    return axios.post('majorevent/saveEventInEntitlementProcess', args)
}

//获取与权利过程关联的重大事件列表
export function getEventListInEntitlementProcess(args) {
    return axios.post('majorevent/getEventListInEntitlementProcess', args)
}

//删除重大事件设置接口
export function deleteEventInEntitlementProcess(args) {
    return axios.post('majorevent/deleteEventInEntitlementProcess', args)
}

//获取重大事件详细信息
export function getSingleMajorEvent(args) {
    return axios.post('majorevent/getSingleMajorEvent', args)
}

// 获取查找信息
export function getLookupInfo(data) {
    return axios.post('/lookup/getLookupInfo', data)
}

// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
    return axios.post('objectdetail/queryMoreDataByName', data)
}