<template>
  <div class="important-event-box">
    <div class="important-event-header">
      <div class="important-event-header-title">
        {{ $t("label.service.major.event") }}
      </div>
      <div class="important-event-header-text">
        {{ $t("label.service.major.event.desc") }}
      </div>
    </div>
    <!--    默认展示列表页-->
    <div v-if="showTable" class="important-event-table">
      <!--    重大事件表格-->
      <div class="important-event-container">
        <div class="important-event-container-option">
          <el-button size="mini" @click="createImEvent">{{
            $t("label.service.major.event.new")
          }}</el-button>
        </div>
        <div class="important-event-container-table">
          <el-table
            v-loading="tableLoading"
            :data="tableData"
            :row-style="{ height: '40px' }"
            :cell-style="{ padding: '0px', color: '#333', fontSize: '14px' }"
            :header-cell-style="{
              background: '#F5F5F5',
              color: '#333',
              height: '40px',
              padding: '0',
            }"
            style="width: 100%"
          >
            <el-table-column
              fixed="left"
              :label="$t('label.department.operation')"
              width="180"
            >
              <template slot-scope="scope">
                <el-button
                  @click="handleImEvent(0, scope.row)"
                  type="text"
                  size="small"
                  class="im-event-item"
                  >{{ $t("label.edit") }}</el-button
                >
                <span style="margin: 0 10px">|</span>
                <el-button
                  @click="handleImEvent(1, scope.row)"
                  type="text"
                  size="small"
                  class="im-event-item"
                  >{{ $t("label.delete") }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column prop="name" :label="$t('label.chatter.name')">
              <template slot-scope="scope">
                <div class="im-event-item" @click="goEventDetail(scope.row, 1)">
                  {{ scope.row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="type"
              :label="$t('label.service.cycle.type')"
            >
              <template slot-scope="scope">
                <div>
                  {{ typeObj[scope.row.type] }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="description"
              :show-overflow-tooltip="true"
              :label="$t('label.recordtype.remark')"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!--    底部分页-->
      <div class="bottom_page">
        <div class="page-option">
          <div class="page-option-l">
            {{ page.total + "&nbsp;" + $t("label.file.items") }}
          </div>
          <div class="page-option-m">
            <el-button
              @click="previousPage"
              type="text"
              size="small"
              :disabled="page.pageNum === 1"
            >
              <!-- 上一页 -->
              {{ $t("label.page.previous") }}
            </el-button>
            <el-button
              @click="nextPage"
              type="text"
              size="small"
              :disabled="page.pageNum > pageNumTotal - 1"
            >
              <!--下一页  -->
              {{ $t("label.page.next") }}
            </el-button>
          </div>
          <div class="page-option-r">
            <!-- 第 -->
            <!-- {{ $t("label.mr.title.button.group1") }} -->
            <!-- 页 -->
            {{ $t("label.brief.page") }}
            <input type="text" v-model="jumpNum" style="margin-left: 8px" />
            <el-button
              type="text"
              size="small"
              class="skip-page-btn"
              @click="jumpPage"
              >GO</el-button
            >
            <!-- 共    -->
            {{ $t("label.multilang.totalpage2") }}
            {{ pageNumTotal }}
            <!-- 页 -->
            {{ $t("label.brief.page") }}
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="$t('label.sure.delete')"
      :visible.sync="dialogDelete"
      width="30%"
    >
      <span>{{ $t("label.sure.delete") + " " + activeItem.name }}？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDelete = false">
          {{ $t("label.cancel") }}
        </el-button>
        <el-button type="primary" @click="confirmDelete">
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as majorEventApi from "./api.js";

export default {
  name: "ImportantEvent",
  data() {
    return {
      typeObj: {
        1: this.$i18n.t("label.service.major.event.independent"),
        2: this.$i18n.t("label.service.major.event.oreder"),
        3: this.$i18n.t("label.service.without.cycle"),
      },
      tableLoading: true, // 表格loading
      dialogDelete: false, // 删除弹窗
      activeItem: {}, //  当前选中条目
      isEditMode: false, // 判断是否为编辑模式
      showTable: true, // 是否展示列表页
      page: {
        total: 100, // 总条数
        pageNum: 1, // 当前页数
      },
      pageNumTotal: 10, // 总页数
      jumpNum: 1, // 要跳转的页数
      tableData: [], // 表格数据
    };
  },
  methods: {
    // 删除
    confirmDelete() {
      majorEventApi
        .deleteMajoreventType({ id: this.activeItem.id })
        .then((res) => {
          if (res.result) {
            this.tableLoading = true;
            this.initPage();
            this.dialogDelete = false;
            this.$message.success(
              res.returnInfo || this.$i18n.t("label.tabpage.delsuccessz")
            );
          }
        });
    },
    // 新建重大事件
    createImEvent() {
      this.isEditMode = false;
      this.activeItem = {};
      this.$router.push({
        path: "importantEvent/edit",
        query: {
          isEditMode: this.isEditMode,
        },
      });
    },
    // 操作某个重大事件
    handleImEvent(flag, item) {
      switch (flag) {
        case 0:
          this.goEventDetail(item, 0);
          break;
        case 1:
          this.activeItem = item;
          this.dialogDelete = true;
          break;
      }
    },
    // 前往重大事件详情
    goEventDetail(item, flag) {
      this.activeItem = item;
      this.isEditMode = true;
      let isDetail = flag > 0 ? "true" : "false";
      this.$router.push({
        path: "importantEvent/edit",
        query: {
          isEditMode: this.isEditMode,
          isDetail: isDetail,
          id: this.activeItem.id,
        },
      });
    },
    // 分页相关操作
    //上一页
    previousPage() {
      if (this.page.pageNum > 1) {
        this.tableLoading = true;
        this.page.pageNum = this.page.pageNum - 1;
        this.jumpNum = this.page.pageNum;
        this.initPage();
      } else {
        // this.$message.info("已经是第一页");
        this.$message.info(this.$i18n.t("label.manageAll.pagenum1"));
      }
    },
    //下一页
    nextPage() {
      if (this.page.pageNum < this.pageNumTotal) {
        this.tableLoading = true;
        this.page.pageNum = this.page.pageNum + 1;
        this.jumpNum = this.page.pageNum;
        this.initPage();
      } else {
        // this.$message.info("已经是最后一页");
        this.$message.info(this.$i18n.t("label.manageAll.pagenum2"));
      }
    },
    // 跳转页面
    jumpPage() {
      this.jumpNum = Number(this.jumpNum);
      if (this.jumpNum <= this.pageNumTotal) {
        this.tableLoading = true;
        this.page.pageNum = this.jumpNum;
        this.initPage();
        // this.jumpNum = "";
      } else {
        this.$message.error(this.$i18n.t("label.field.input.number"));

        // this.jumpNum = "";
      }
    },
    initPage() {
      let obj = {
        pageNum: this.page.pageNum,
        pageSize: 10,
      };
      majorEventApi.getMajoreventTypeList(obj).then((res) => {
        if (res.result) {
          this.tableData = res.data.majoreventTypeList;
          this.page.total = res.data.totalCount;
          this.pageNumTotal = Math.ceil(res.data.totalCount / 10);
          this.tableLoading = false;
        }
      });
    },
  },
  mounted() {
    this.initPage();
  },
  filters: {
    typeStr(str) {
      let tempFlag;
      switch (str) {
        case "1":
          tempFlag = this.$i18n.t("label.service.major.event.independent") || 1;
          break;
        case "2":
          tempFlag = this.$i18n.t("label.service.major.event.oreder") || 2;
          break;
        case "3":
          tempFlag = this.$i18n.t("label.service.without.cycle") || 3;
          break;
      }
      return tempFlag;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item{
  margin-bottom: 0;
}
.important-event-box {
  padding: 18px 10px;
  box-sizing: border-box;
  .important-event-header {
    color: #333333;
    &-title {
      font-size: 20px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    &-text {
      font-size: 14px;
      margin-bottom: 30px;
    }
  }
  .important-event-table {
    .important-event-container {
      background: #f5f5f5;
      border: 1px solid #dcdcdc;
      border-radius: 3px;
      width: 100%;
      padding: 0 10px 10px;
      box-sizing: border-box;
      &-option {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 60px;
      }
      &-table {
        border: 1px solid #dcdcdc;
        border-radius: 3px;
        .im-event-item {
          color: #006dcc;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    .bottom_page {
      height: 40px;
      padding: 0 15px;
      background: #ffffff;
      border-radius: 0 0 3px 3px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .page-option {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .page-option-l {
          display: flex;
          align-items: center;
        }
        .page-option-m {
          display: flex;
          .el-button {
            border: none;
            font-size: 12px;
            // &:hover {
            //   background: none;
            // }
          }
        }
        .page-option-r {
          display: flex;
          align-items: center;
          input {
            width: 32px;
            height: 20px;
            outline: none;
            border: 1px solid #ccc;
            padding: 0 6px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .skip-page-btn {
            margin: 0 5px;
            height: 22px;
            padding: 0 5px;
            border: 1px solid #ccc;
            background: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            outline: none;
            cursor: pointer;
          }
        }
      }
    }
  }
  .important-event-operation {
    background: #f5f5f5;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    &-t {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      .new-event-l,
      .new-event-r {
        width: 15%;
        font-size: 16px;
      }
      .new-event-l {
        color: #333333;
      }
      .new-event-r {
        text-align: right;
        color: #151515;
        span {
          color: #c23934;
        }
      }
      .new-event-m {
      }
    }
    &-c {
      padding: 16px 190px 30px;
      border: 1px solid #dcdcdc {
        left: 0;
        right: 0;
      }
    }
    &-b {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
    }
  }
}
</style>
